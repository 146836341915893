import { Controller } from '@hotwired/stimulus'

export default class BackupController extends Controller {

    static targets = ['name', 'environment', 'resultBox', 'resultsTile']

    filterBackupsByText() {
        this.resultsTileTargets.forEach(element => {
            if (checkDatabases (element, this.nameTargets[0].value.toLowerCase(), this.environmentTarget.value.toLowerCase())) {
                element.style.display = 'block'
            } else {
                element.style.display = 'none'
            }
        })
    }

    filterBackupsBySelect() {
        this.resultsTileTargets.forEach(element => {
            if (checkDatabases (element, this.nameTargets[0].value.toLowerCase(), this.environmentTarget.value.toLowerCase())) {
                element.style.display = 'block'
            } else {
                element.style.display = 'none'
            }
        })
    }
}

function checkDatabases (element, user_input, env) {
    return element.querySelector("#backupTileName").innerHTML.toLowerCase().includes(user_input) && element.querySelector("#backupTileName").innerHTML.toLowerCase().includes(env)
}