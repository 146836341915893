import { Application } from "@hotwired/stimulus"
import { tippy } from 'tippy.js';
// import 'tippy.js/dist/tippy.css';

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
